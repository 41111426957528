import React from 'react';
import './Navbar.css';

//import icons
import imghome from '../../assets/navbar/home.png';
import imghomeaktiv from '../../assets/navbar/home-aktiv.png';
import imgevents from '../../assets/navbar/events.png';
import imgeventsaktiv from '../../assets/navbar/event-aktiv.png';
import imgstore from '../../assets/navbar/store.png';
import imgstoreaktiv from '../../assets/navbar/store-aktiv.png';
import imgprofil from '../../assets/navbar/profil.png';
import imgprofilaktiv from '../../assets/navbar/profil-aktiv.png';
import imgconfetti from '../../assets/navbar/confetti.png';
import { useHistory, useLocation } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import EventStore from '../../mobx/EventStore';

interface NavbarProps {
  eventStore?: EventStore;
}

const Navbar = ({ eventStore }: NavbarProps) => {
  const location = useLocation();
  const history = useHistory();

  function checkIfIphoneX() {
    var iOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
    var ratio = window.devicePixelRatio || 1;

    // Define the users device screen dimensions
    var screen = {
      width: window.screen.width * ratio,
      height: window.screen.height * ratio,
    };

    // iPhone X Detection
    if (iOS && screen.width == 1125 && screen.height === 2436) {
      // Set a global variable now we've determined the iPhoneX is true
      return true;
    } else {
      return false;
    }
  }

  function isEventsRouteActive() {
    if (location.pathname === '/events') {
      return true;
    }
    if (location.pathname === '/eventlist') {
      return true;
    }
    if (location.pathname.includes('/eventdetails')) {
      return true;
    }
    return false;
  }

  function isStoreRouteActive() {
    if (location.pathname === '/store') {
      return true;
    }
    if (location.pathname === '/shopitemlist') {
      return true;
    }
    if (location.pathname === '/storeitem') {
      return true;
    }
    if (location.pathname === '/storeitem') {
      return true;
    }
    if (location.pathname.indexOf('/product') !== -1) {
      return true;
    }
    if (location.pathname === '/shoppingcart') {
      return true;
    }
    if (location.pathname === '/shipmentaddress') {
      return true;
    }
    if (location.pathname === '/payment') {
      return true;
    }
    if (location.pathname === '/orderreview') {
      return true;
    }
    if (location.pathname === '/orderdone') {
      return true;
    }
    if (location.pathname.indexOf('/customproduct') !== -1) {
      return true;
    }
    return false;
  }

  function isProfileRouteActive() {
    if (location.pathname === '/profil') {
      return true;
    }
    if (location.pathname === '/personalspace') {
      return true;
    }
    if (location.pathname === '/lotteryprices') {
      return true;
    }
    if (location.pathname === '/transaction') {
      return true;
    }
    if (location.pathname === '/faq') {
      return true;
    }
    if (location.pathname === '/impressum') {
      return true;
    }
    if (location.pathname === '/orderhistory') {
      return true;
    }
    if (location.pathname === '/teilnahmebedingung') {
      return true;
    }
    if (location.pathname === '/datenschutz') {
      return true;
    }

    if (location.pathname === '/leaderboard') {
      return true;
    }
    return false;
  }

  function isHomeRouteActive() {
    if (location.pathname === '/home') {
      return true;
    }
    return false;
  }

  if (
    window.localStorage.getItem('user') &&
    window.localStorage.getItem('mohren_token') &&
    location.pathname !== '/username'
  ) {
    return (
      <div
        className='main-navbar'
        style={{ paddingBottom: checkIfIphoneX() ? 5 : 0 }}
      >
        <div style={{ width: 600 }}>
          <div className='navbar-container'>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {isHomeRouteActive() && (
                <div
                  className='navbar-item-container-img'
                  onClick={() => {
                    history.push('/home');
                  }}
                >
                  <img src={imghomeaktiv} className='navbar-icon' />
                </div>
              )}
              {!isHomeRouteActive() && (
                <div
                  className='navbar-item-container-img'
                  onClick={() => {
                    history.push('/home');
                  }}
                >
                  <img src={imghome} className='navbar-icon' />
                </div>
              )}
              {isEventsRouteActive() && (
                <div
                  className='navbar-item-container-img'
                  onClick={() => {
                    history.push('/events');
                  }}
                >
                  <img src={imgeventsaktiv} className='navbar-icon' />
                </div>
              )}
              {!isEventsRouteActive() && (
                <div
                  className='navbar-item-container-img'
                  onClick={() => {
                    history.push('/events');
                  }}
                >
                  <img src={imgevents} className='navbar-icon' />
                </div>
              )}

              {isStoreRouteActive() && (
                <div
                  className='navbar-item-container-img'
                  onClick={() => {
                    history.push('/store');
                  }}
                >
                  <img src={imgstoreaktiv} className='navbar-icon' />
                </div>
              )}
              {!isStoreRouteActive() && (
                <div
                  className='navbar-item-container-img'
                  onClick={() => {
                    history.push('/store');
                  }}
                >
                  <img src={imgstore} className='navbar-icon' />
                </div>
              )}
              {isProfileRouteActive() && (
                <div
                  onClick={() => {
                    history.push('/profil');
                  }}
                  className='navbar-item-container-img'
                >
                  {' '}
                  <img src={imgprofilaktiv} className='navbar-icon' />
                </div>
              )}
              {!isProfileRouteActive() && (
                <div
                  className='navbar-item-container-img'
                  onClick={() => {
                    history.push('/profil');
                  }}
                >
                  <img src={imgprofil} className='navbar-icon' />
                </div>
              )}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {isHomeRouteActive() && (
                <div
                  onClick={() => {
                    history.push('/home');
                  }}
                  className='navbar-item-container'
                >
                  <div className='navbar-subtitle-aktiv'>Home</div>
                </div>
              )}
              {!isHomeRouteActive() && (
                <div
                  onClick={() => {
                    history.push('/home');
                  }}
                  className='navbar-item-container'
                >
                  <div className='navbar-subtitle'>Home</div>
                </div>
              )}
              {isEventsRouteActive() && (
                <div
                  onClick={() => {
                    history.push('/events');
                  }}
                  className='navbar-item-container'
                >
                  <div className='navbar-subtitle-aktiv'>Events</div>
                </div>
              )}
              {!isEventsRouteActive() && (
                <div
                  onClick={() => {
                    history.push('/events');
                  }}
                  className='navbar-item-container'
                >
                  <div className='navbar-subtitle'>Events</div>
                </div>
              )}

              {isStoreRouteActive() && (
                <div
                  onClick={() => {
                    history.push('/store');
                  }}
                  className='navbar-item-container'
                >
                  <div className='navbar-subtitle-aktiv'>s’Lädele</div>
                </div>
              )}
              {!isStoreRouteActive() && (
                <div
                  onClick={() => {
                    history.push('/store');
                  }}
                  className='navbar-item-container'
                >
                  <div className='navbar-subtitle'>s’Lädele</div>
                </div>
              )}
              {isProfileRouteActive() && (
                <div
                  onClick={() => {
                    history.push('/profil');
                  }}
                  className='navbar-item-container'
                >
                  <div className='navbar-subtitle-aktiv'>Profil</div>
                </div>
              )}
              {!isProfileRouteActive() && (
                <div
                  onClick={() => {
                    history.push('/profil');
                  }}
                  className='navbar-item-container'
                >
                  <div className='navbar-subtitle'>Profil</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default inject('eventStore')(observer(Navbar));
