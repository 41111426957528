import React, { Component, useEffect } from "react";
import "./Profil.css";
import Header from "../../components/header/Header";

//import icons
import imgprofil from "../../assets/profil/profil.png";
import imglogout from "../../assets/profil/logout.png";
import imgleaderboard from "../../assets/leaderboard.png";
import AuthGuard from "../../services/UserAuth.service";
import placeholder from "../../assets/profilbild_placeholder.png";
import orderIcon from "../../assets/order.png";
import ticketIcon from "../../assets/icon_gewinnlos.png";
import plus from "../../assets/plus-green.png";

import Loading from "../../components/loading/Loading";
import { inject, observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import TeamHelper from "../../services/TeamHelper.service";

declare var grecaptcha: any;
declare var window: any;

interface ProfilProps {
  userStore?: any;
}

const Profil = ({ userStore }: ProfilProps) => {
  const history = useHistory();

  useEffect(() => {
    userStore.getCurrentUser();

    grecaptcha.ready(function () {
      grecaptcha.execute("6LdDNqEUAAAAABH82phBBZchoJO0rQXVJM-SXUpk", {
        action: "shop_screen",
      });
    });
    window.scroll(0, 0);
    return () => {};
  }, []);

  function getImage() {
    if (userStore.currentUser.image) {
      return userStore.currentUser.image.url;
    } else {
      if (userStore.currentUser.player) {
        return TeamHelper.getByKey(userStore.currentUser.player).portrait;
      } else {
        return placeholder;
      }
    }
  }

  return (
    <div className="profile-container">
      <Header />
      <div className="profil-header">
        <div className="personal-space-image-container">
          <div style={{ position: "relative" }}>
            <img src={getImage()} className="personal-space-image" />
            <div
              className="personal-space-image-btn-container"
              style={{ right: 0 }}
            >
              <img
                src={plus}
                className="personal-space-image-btn"
                style={{ right: 0 }}
                onClick={() => history.push("/personalspace")}
              />
            </div>
          </div>
        </div>
        <div className="profil-header-text-block">
          {userStore.currentUser.username ? (
            <div className="profile-username">
              {userStore.currentUser.username}
            </div>
          ) : (
            <></>
          )}
          {userStore.currentUser.fname || userStore.currentUser.lname ? (
            <div className="profile-username" style={{ fontWeight: "normal" }}>
              {(userStore.currentUser.fname
                ? userStore.currentUser.fname + " "
                : "") +
                (userStore.currentUser.lname
                  ? userStore.currentUser.lname
                  : "")}
            </div>
          ) : (
            <></>
          )}
          {userStore.currentUser.plz && userStore.currentUser.location ? (
            <div className="profile-address">
              {userStore.currentUser.plz + " " + userStore.currentUser.location}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="profil-links">
        <div className="profil-spacer" style={{ marginBottom: 20 }}></div>
        <div
          className="profil-link"
          onClick={() => {
            history.push("/personalspace");
          }}
        >
          <img
            src={imgprofil}
            className="profil-link-icon"
            style={{ width: 15, marginRight: 5 }}
          />
          Persönliche Daten
        </div>
        <div
          className="profil-link"
          onClick={() => {
            history.push("/orderhistory");
          }}
        >
          <img
            src={orderIcon}
            className="profil-link-icon"
            style={{ width: 20 }}
          />
          Bestellübersicht
        </div>
        {/* <div
          className="profil-link"
          onClick={() => {
            history.push("/leaderboard");
          }}
        >
          <img
            src={imgleaderboard}
            className="profil-link-icon"
            style={{ height: 20, width: 18 }}
          />
          Torschützenliste
        </div> */}
        <div className="profil-spacer" style={{ marginBottom: 20 }}></div>
        <div
          className="profil-link"
          onClick={() => {
            userStore.logout();
            history.push("/");
          }}
        >
          <img
            src={imglogout}
            className="profil-link-icon"
            style={{ height: 17, width: 15 }}
          />
          Abmelden
        </div>
        <div className="profil-spacer" style={{ marginBottom: 20 }}></div>
        <div
          className="profile-link-agb"
          onClick={() => {
            history.push("/faq");
          }}
        >
          Hilfe {"&"} FAQs
        </div>
        <div
          className="profile-link-agb"
          onClick={() => {
            history.push("/teilnahmebedingung");
          }}
        >
          Teilnahmebedingungen
        </div>
        {/* <div className="profile-link-agb" onClick={() => { this.props.push("/teilnahmebedingungmeinspezial") }}>Teilnahmebedingungen „MeinSpezial“</div> */}
        <div
          className="profile-link-agb"
          onClick={() => {
            history.push("/datenschutz");
          }}
        >
          Datenschutz
        </div>
        <div
          className="profile-link-agb"
          onClick={() => {
            history.push("/impressum");
          }}
        >
          Impressum
        </div>
      </div>
      <div style={{ height: 50 }}></div>
      <Loading />
    </div>
  );
};

export default inject("userStore")(observer(Profil));
