import React, { Component } from "react";
import "./NewsCard.css";
import ImageWithSkeleton from "../../../components/ImageWithSkeleton/ImageWithSkeleton";
import News from "../../../models/News";
import NewsStore from "../../../mobx/NewsStore";
import { observer, inject } from "mobx-react";
import { useHistory } from "react-router-dom";

interface NewsCardProps {
  item?: News;
  newsStore?: NewsStore;
}

const NewsCard = ({ item, newsStore }: NewsCardProps) => {
  const history = useHistory();

  if (!item) {
    return <></>;
  }

  return (
    <div
      className="news-card"
      onClick={() => {
        if (item && newsStore) {
          history.push("/newsdetail/" + item.id);
        }
      }}
    >
      {item.previewImage && (
        <div className="news-card-small">
          <ImageWithSkeleton
            image={item.previewImage.url}
            imageClassName="news-card-fullscreen-image"
            width="100%"
            height="160px"
            borderRadius="5px"
          />
        </div>
      )}
    </div>
  );
};

export default inject("newsStore")(observer(NewsCard));
