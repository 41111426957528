import React, { useEffect } from 'react';
import './HomePage.css';

import { inject, observer } from 'mobx-react';

import Header from '../../components/header/Header';

import { useHistory } from 'react-router-dom';
import UserStore from '../../mobx/UserStore';
import { isIOS } from 'react-device-detect';

import EventStore from '../../mobx/EventStore';
import shopBackground from '../../assets/home_shop_card.jpg';

import NewsStore from '../../mobx/NewsStore';
import CardHuge from '../../components/card-huge/CardHuge';
import EventsCarousel from '../../components/EventsCarousel/EventsCarousel';
import NewsCarousel from '../../components/NewsCarousel/NewsCarousel';

declare var grecaptcha: any;

interface HomeProps {
  userStore: UserStore;
  eventStore: EventStore;
  newsStore: NewsStore;
}

const HomePage = ({ userStore, eventStore, newsStore }: HomeProps) => {
  const history = useHistory();

  useEffect(() => {
    newsStore.loadNews();
    eventStore.loadEvents();

    userStore.getCurrentUser();

    if (userStore.currentUser) {
      if (!userStore.currentUser.username) {
        history.push('/username');
      }
    }

    grecaptcha.ready(function () {
      grecaptcha.execute('6LdDNqEUAAAAABH82phBBZchoJO0rQXVJM-SXUpk', {
        action: 'home_screen',
      });
    });
  }, []);

  if (!userStore.currentUser) {
    return <></>;
  }
  return (
    <div>
      <div className='fullscreen'>
        {/* <div> */}
        {/*<img src={slogan} className="banner"/>*/}
        {/* <img src={silvester} className='fullscreen-image' /> */}
        {/* </div> */}
        {/*<WhiteButton*/}
        {/*    style={{position: 'absolute', bottom: 43, left: 25, right: 25, maxWidth: 270, margin: '0 auto'}}*/}
        {/*    label={"Bierdeckel Scanner"} action={() => {*/}
        {/*    history.push("/ar-camera")*/}
        {/*}}/>*/}
        <div
          className='default-margin-10'
          style={{ marginTop: 25, paddingBottom: 50 }}
        >
          {newsStore.news.length > 0 ? (
            <>
              <div>
                <div
                  className='event-card-title'
                  style={{ paddingLeft: 15, paddingBottom: 10 }}
                >
                  News
                </div>
                <NewsCarousel items={newsStore.news} />
              </div>
              <div
                className='shop-divider'
                style={{ marginBottom: 25, marginTop: 15 }}
              ></div>
            </>
          ) : (
            <></>
          )}
          <CardHuge
            backgroundImage={shopBackground}
            backgroundColor='#008a4c'
            title={''}
            hideButton
            buttonText='Los gehts'
            onClick={() => {
              history.push('/store');
            }}
          />
          {eventStore.events.length > 0 ? (
            <div>
              <div className='event-container-home'>
                <div
                  className='shop-divider'
                  style={{ marginTop: 25, marginBottom: 25 }}
                ></div>
                <div className='event-card-title'>Events</div>
                <div
                  className='event-card-subtitle'
                  onClick={() => {
                    history.push('/events');
                  }}
                >
                  Alle anzeigen {'>'}
                </div>
              </div>
              <div className='event-carousel-container'>
                <EventsCarousel />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <Header cameraEnabled={isIOS} />
      </div>
    </div>
  );
};

export default inject(
  'userStore',
  'eventStore',
  'newsStore'
)(observer(HomePage));
