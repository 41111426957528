import React, { Component } from 'react';
import Slider from 'react-slick';
import NewsCard from '../../pages/Events/NewsCard/NewsCard';
import News from '../../models/News';

interface NewsCarouselProps {
  items: News[];
}

const NewsCarousel = ({ items }: NewsCarouselProps) => {
  var loading = [{}, {}];

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (items) {
    return (
      <Slider {...settings}>
        {items &&
          items.map(function (item, index) {
            return (
              <div key={index} className='shop-item-overlapping-card'>
                <NewsCard key={index} item={item} />
              </div>
            );
          })}
      </Slider>
    );
  } else {
    return (
      <Slider {...settings}>
        {loading.map(function (item, index) {
          return (
            <div key={index} className='shop-item-overlapping-card'>
              <NewsCard key={index} item={undefined} />
            </div>
          );
        })}
      </Slider>
    );
  }
};

export default NewsCarousel;