import React, { Component } from "react";
import "./CardHuge.css";
import YellowButton from "../Yellow-Button/Yellow-Button";
import WhiteButton from "../White-Button/White-Button";

interface CardHugeProps {
  title?: string;
  subtitle?: string;
  onClick: any;
  backgroundImage: string;
  buttonText: string;
  hideButton?: boolean;
  backgroundColor?: string;
  isButtonWhite?: boolean;
  readonly?: boolean;
}

class CardHuge extends Component<CardHugeProps, {}> {
  render() {
    return (
      <div
        className={"cardhuge-container " + (this.props.readonly ? 'readonly' : '')}
        onClick={() => {
          if (!this.props.readonly) {
            this.props.onClick();
          }
        }}
      >
        <div
          className="image-container"
          style={{
            backgroundColor: this.props.backgroundColor
              ? this.props.backgroundColor
              : "#004f2b",
          }}
        >
          <img src={this.props.backgroundImage} alt="background_image" />
          {!this.props.hideButton && (
            <div className="cardHuge-text-container">
              {this.props.isButtonWhite ? (
                <WhiteButton
                  label={this.props.buttonText}
                  action={this.props.onClick}
                />
              ) : (
                <YellowButton
                  label={this.props.buttonText}
                  onClick={this.props.onClick}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CardHuge;
