import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import Slider from 'react-slick';
import EventStore from '../../mobx/EventStore';

import './EventsCarousel.css';

import EventCard from '../../pages/Events/Events-Card/EventCard';

interface EventsCarouselProps {
  //   items: any[];
  cardstyle?: any;
  hideLocation?: boolean;
  eventStore?: EventStore;
}

const EventsCarousel = ({
  //   items,
  cardstyle,
  hideLocation,
  eventStore,
}: EventsCarouselProps) => {
  var loading = [{}, {}];

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (eventStore!.events) {
    return (
      <Slider {...settings}>
        {eventStore!.events &&
          eventStore!.events.map(function (item, index) {
            return (
              <div key={index} className='shop-item-overlapping-card'>
                <EventCard
                  key={index}
                  imageFullscreen={true}
                  item={item}
                  style={cardstyle}
                  hideLocation={hideLocation}
                />
              </div>
            );
          })}
      </Slider>
    );
  } else {
    return (
        <Slider {...settings}>
          {loading.map(function (item, index) {
            return (
              <div key={index} className='shop-item-overlapping-card'>
                <EventCard
                  key={index}
                  imageFullscreen={true}
                  style={cardstyle}
                  hideLocation={hideLocation}
                />
              </div>
            );
          })}
        </Slider>
    );
  }
};

export default inject('eventStore')(observer(EventsCarousel));
