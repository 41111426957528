import { observable, computed, action } from 'mobx';
import Event from '../models/Event';
import StrapiHelper from '../services/StrapiHelper.service';

export default class EventStore {
  @observable
  events: Event[] = [];

  @observable
  currentEvent?: Event;

  @observable
  featuredEvent?: Event;

  @observable
  loaded: boolean = false;

  @action
  setCurrentEvent(event?: Event) {
    this.currentEvent = event;
  }

  @action
  async getEvent(id: string): Promise<Event> {
    let res = await StrapiHelper.loadEvent(id);
    let event = res.data as Event;
    return event;
  }

  @action
  async loadEvents() {
    try {
      let res = await StrapiHelper.loadEvents();
      let events = res.data as Event[];
      this.events = events;
      this.loaded = true;
    } catch (err) {
      console.log('err');
    }
  }

  @action
  async loadFeaturedEvent() {
    let res = await StrapiHelper.loadFeaturedEvent();
    let event = res.data as Event[];
    if (event && event.length > 0) {
      this.featuredEvent = event[0];
    }
  }
}
